import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Error } from "@progress/kendo-react-labels";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Upload } from "./../Core/Components/Form/Upload";
import { changePassword } from "./Services/PasswordChangeService";
import { getPasswordPolicy } from "../Login/Services/LoginService";
import { useAuth } from "../Core/Context/AuthContext";
import { Form, Field } from "@progress/kendo-react-form";
import {
  Loader,
  ErrorFallback,
  TextInput,
  buildNotification,
  GenerateNotification,
  MessageAlert,
} from "smart-react";
import { isValidate, isFromValid } from "../../../src/Utils/Forms/Form";
import {
  ERROR_MESSAGE,
  UPDATE_PASSWORD_MESSAGE,
  PASSWORD_NOT_MATCH,
} from "../../constants/notificationMessages";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../constants/eventDataTypes";
import "./PasswordChange.scss";

const PasswordChange = () => {
  const { profileData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [validateForm, setValidateForm] = React.useState(false);
  const [policyPayload, setPolicyPayload] = React.useState({});
  const [formData, setFormData] = React.useState({
    ExistingPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
    Active: true,
  });
  const [alertMessage, setAlertMessage] = React.useState({
    alert: false,
    message: null,
    type: "danger",
    iconName: "error",
  });
  const requiredFields = ["ExistingPassword", "NewPassword", "ConfirmPassword"];
  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  /**
   * checkPasswordPolicy
   * @poram gatewayId
   */
  const checkPasswordPolicy = async ({ gatewayId }) => {
    try {
      const response = await getPasswordPolicy({ gatewayId });
      if (response?.IsSuccess) {
        setPolicyPayload(response?.Payload);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async () => {
    setValidateForm(true);
    if (formData?.NewPassword !== formData?.ConfirmPassword) {
      setAlertMessage({
        alert: true,
        type: "danger",
        message: PASSWORD_NOT_MATCH?.description,
        iconName: "error",
      });
      return;
    }
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }

    const regexPattern = new RegExp(policyPayload?.Regex);
    // Check against the dynamic regex
    if (!regexPattern.test(formData?.NewPassword)) {
      setAlertMessage({
        alert: true,
        type: "danger",
        message: policyPayload?.PolicyMessage,
        iconName: "error",
      });
      return;
    }
    setLoading(true);
    const data = formData;
    let response;

    try {
      setValidateForm(false);
      response = await changePassword({
        data,
        isServiceWorker: false,
      });
      console.log("response", response);

      if (!response.IsSuccess) {
        setAlertMessage({
          alert: true,
          type: "danger",
          message: response?.StatusDesc,
          iconName: "error",
        });
      } else {
        setFormData({
          ExistingPassword: "",
          NewPassword: "",
          ConfirmPassword: "",
          Active: true,
        });
        setAlertMessage({
          alert: true,
          type: "success",
          message: UPDATE_PASSWORD_MESSAGE?.description,
          isIcon: false,
          iconName: "check",
        });
        GenerateNotification(
          buildNotification(UPDATE_PASSWORD_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (profileData?.Appuservm?.AuthGatewayId) {
      checkPasswordPolicy({ gatewayId: profileData?.Appuservm?.AuthGatewayId });
    }
  }, [profileData]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        <div className={`add-test main-page-section add-page-section `}>
          <Card className="detail-card">
            <CardHeader>
              <h6 className="card-title-secondary">Profile</h6>
            </CardHeader>
            <CardBody>
              <div
                id="Profile"
                className="profile-page main-content container mx-auto"
              >
                {loading && <Loader />}
                <div className="card-row">
                  <div className="card-column user-av-wrapper">
                    <Upload />
                  </div>
                  <div className="card-column fields-column k-align-items-center k-d-flex k-justify-content-center">
                    <div className="user-profile">
                      <div className="k-d-flex field-row">
                        <div>
                          <strong className="field-label">Name:</strong>{" "}
                          <span className="field-value">
                            {profileData?.Appuservm?.FirstName}{" "}
                            {profileData?.Appuservm?.LastName}
                          </span>
                        </div>
                        <div className="email-row">
                          <strong className="field-label">Email:</strong>{" "}
                          <span className="field-value">
                            {profileData?.Appuservm?.EmailAddress}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="k-mt-3 detail-card">
            <CardHeader>
              <h6 className="card-title-secondary">Change Password</h6>
            </CardHeader>
            <CardBody>
              {alertMessage?.alert && (
                <MessageAlert
                  type={alertMessage?.type}
                  message={alertMessage?.message}
                  isIcon={true}
                  iconName={alertMessage?.iconName}
                />
              )}
              <div className="detail-card-body">
                <Form
                  onSubmit={onSave}
                  render={(formRenderProps) => (
                    <div className="card-content">
                      <div className="k-d-flex single-field-row">
                        <div
                          className={`field-wrapper ${isValidate(validateForm, formData?.ExistingPassword) ? "field-invalid" : "field-valid"}`}
                        >
                          <Field
                            key={"ExistingPassword"}
                            id={"ExistingPassword"}
                            name={"ExistingPassword"}
                            data={formData?.ExistingPassword}
                            onChange={handleInputChange}
                            label={"Old Password"}
                            type={"password"}
                            component={TextInput}
                            optional={false}
                            requiredField={true}
                          />
                          {isValidate(
                            validateForm,
                            formData?.ExistingPassword
                          ) && <Error>This field cannot be empty</Error>}
                        </div>
                      </div>
                      <div className="k-d-flex single-field-row">
                        <div
                          className={`field-wrapper ${isValidate(validateForm, formData?.NewPassword) ? "field-invalid" : "field-valid"}`}
                        >
                          <Field
                            key={"NewPassword"}
                            id={"NewPassword"}
                            name={"NewPassword"}
                            data={formData?.NewPassword}
                            onChange={handleInputChange}
                            label={"New Password"}
                            type={"password"}
                            component={TextInput}
                            optional={false}
                            requiredField={true}
                          />
                          {isValidate(validateForm, formData?.NewPassword) && (
                            <Error>This field cannot be empty</Error>
                          )}
                        </div>
                      </div>
                      <div className="k-d-flex single-field-row">
                        <div
                          className={`field-wrapper ${isValidate(validateForm, formData?.ConfirmPassword) ? "field-invalid" : "field-valid"}`}
                        >
                          <Field
                            key={"ConfirmPassword"}
                            id={"ConfirmPassword"}
                            name={"ConfirmPassword"}
                            data={formData?.ConfirmPassword}
                            onChange={handleInputChange}
                            label={"Confirm Password"}
                            type={"password"}
                            component={TextInput}
                            optional={false}
                            requiredField={true}
                          />
                          {isValidate(
                            validateForm,
                            formData?.ConfirmPassword
                          ) && <Error>This field cannot be empty</Error>}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </CardBody>
          </Card>
          <Card className="k-mt-3">
            <CardHeader>
              <div className="k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0">
                <>
                  <Button
                    text="save"
                    themeColor={"primary"}
                    onClick={() => onSave({ isExit: false })}
                    type="button"
                  >
                    Save
                  </Button>
                </>
              </div>
            </CardHeader>
          </Card>
        </div>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default PasswordChange;
